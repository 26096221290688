import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {NavLink} from "react-router-dom";
import {Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useAuth} from "../../store/auth-context";

const pages = [
    {
        icon: <AdbIcon />,
        title: 'Lista File',
        link: '/dashboard',
    }
    ];

const MobileNavigation = ({onCloseNav, onOpenNav, anchorNav}) => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return <>
        <Box sx={{ width: 250,  display: { xs: 'flex', md: 'none' }  }} role="presentation" onClick={toggleDrawer(false)}>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <List>
                    {pages.map(({title, link, icon}) => (
                        <ListItem key={title} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1 }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawer(true)}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <AdbIcon sx={{ mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Sbobbi
                    </Typography>
                    <UserMenu />
                </Toolbar>
            </Container>
        </Box>
    </>
}

const DesktopNavigation = ({onCloseNav}) => {
    return <>
        <Box sx={{display: { xs: 'none', md: 'flex' }}}>
            <Container maxWidth="xl">
                <Toolbar>
                    <AdbIcon sx={{ mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 4,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Sbobbi
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        {pages.map((page) => (
                            <NavLink
                                to={page.link}
                                style={ ({isActive}) => {
                                    return (isActive) ? {
                                        fontWeight: 'bold',
                                        textDecoration: 'none',
                                    } : {
                                        textDecoration: 'none',
                                    }
                                }}
                            >
                                <Button
                                    key={page.title}
                                    onClick={onCloseNav}
                                    sx={{ my: 2, fontWeight: 'inherit', color: 'white', display: 'block' }}
                                >
                                    {page.title}
                                </Button>
                            </NavLink>
                        ))}
                    </Box>
                    <UserMenu />
                </Toolbar>
            </Container>
        </Box>
    </>
}

const UserMenu = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const authCtx = useAuth();

    const settings = [{
        label: 'Logout',
        onClick: () => {
            authCtx.logout();
        }
    }];

    return <>
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={authCtx.user ? authCtx.user.name : "User"} src="/static/images/avatar/2.jpg" />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <Box onClick={handleCloseUserMenu}>
                        <MenuItem key={setting.label} onClick={setting.onClick}>
                            <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
                        </MenuItem>
                    </Box>
                ))}
            </Menu>
        </Box>
    </>;
}

const Navigation = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static">
            <DesktopNavigation anchorNav={anchorElNav} onCloseNav={handleCloseNavMenu} onOpenNav={handleOpenNavMenu} />
            <MobileNavigation anchorNav={anchorElNav} onCloseNav={handleCloseNavMenu} onOpenNav={handleOpenNavMenu} />
        </AppBar>
    );
}

export default Navigation;