import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useApiHook from "../hooks/useApiHook";

const AuthContext = createContext({
    user: null,
    token: null,
    response: null
})

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [response, setResponse] = useState(null);
    const navigate = useNavigate();
    const {response: responseSuccess, error: responseError, makeRequest} = useApiHook();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
            makeRequest('auth/autologin', 'post', {requestType: 'autologin'});
        }
    }, []);

    useEffect(() => {
        setResponse(responseError);
    }, [responseError]);

    useEffect(() => {
        const res = responseSuccess;
        if (!res) return;
        switch (res.request.requestType) {
            case 'register':
                setResponse(res);
                break;
            case 'login':
            case 'autologin':
                setResponse(res);
                if (res.state === 'success'){
                    console.log('Login successful');
                    setToken(res.data.token);
                    localStorage.setItem('token', res.data.token);
                    setUser(res.data.user);
                    navigate("/");
                } else {
                    setToken(null);
                    localStorage.removeItem('token');
                    setUser(null);
                    navigate("/");
                }
                break;
            case 'logout':
                break;
        }
    }, [responseSuccess]);

    const register = (email, password, name, lastname) => {
        makeRequest('auth/register', 'post', {email, password, name, lastname, requestType: 'register'});
    }

    const login = (email, password) => {
        makeRequest('auth/login', 'post', {email, password, requestType: 'login'});
    }

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
        makeRequest('auth/logout', 'post', {token, requestType: 'logout'});
        navigate("/");
    }

    return (
        <AuthContext.Provider value={{ user, token, response, register, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);