import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import Typography from "@mui/material/Typography";
import {Button, Skeleton, Stack} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useApiHook from "../hooks/useApiHook";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import moment from "moment/moment";

moment.locale('it');

const FileDetails = (props) => {
    const {response, makeRequest, error} = useApiHook();
    const {fileId } = useParams();
    const navigate = useNavigate();

    const [fileDetails, setFileDetails] = useState(null);
    const [transcription, setTranscription] = useState([]);

    const deleteBtnClickHandler = () => {
        makeRequest('file/delete', 'post', {fileId });
        navigate('/');
    }

    useEffect(() => {
       makeRequest(`file/${fileId}/details`);
    }, [])

    useEffect(() => {
        if (response && response.state === 'success') {
            console.log(response);

            response.data.formattedDate = moment(response.data.createdAt).format('D MMMM YYYY, HH:mm:ss');
            if (response.data.transcription) {
                setTranscription(JSON.parse(response.data.transcription));
            }
            setFileDetails(response.data);
        }
    }, [response])

    const transcriptionList =  transcription.map(sentence => {
        return <>
            <Stack my={2} direction="row" spacing={1}>
                <Typography variant='body1' fontWeight='bold' color={'primary'}>{sentence.speaker}: </Typography>
                <Typography variant='body1'>{sentence.text}</Typography>
            </Stack>
        </>
    });
    return <>
        { !fileDetails && <Skeleton variant='rectangular' height={42} ></Skeleton>}
        { fileDetails && <>
            <Stack spacing={2} direction="row" alignItems="center" >
                <LibraryBooksIcon fontSize="large" />
                <Typography variant='h4' fontWeight='bold' flexGrow={1}>{fileDetails.name}</Typography>
                <Button
                    size="large"
                    variant="contained"
                    color={"error"}
                    onClick={deleteBtnClickHandler}
                    startIcon={<DeleteForeverIcon />}>
                    Elimina File
                </Button>
            </Stack>
        </>}
        <Box my={1}>
            { !fileDetails && <Skeleton variant='rectangular' height={24} ></Skeleton>}
            { fileDetails && <Typography variant='body1'>
                {fileDetails.formattedDate}
            </Typography>}
        </Box>
        <Box my={3}>
            { (!transcription.length && !fileDetails) && <Skeleton variant='rectangular' height={150} ></Skeleton>}
            { (!transcription.length && fileDetails) && <Typography variant='h6'>Generazione testo in corso...</Typography> }
            { (transcription.length > 0 && fileDetails) && transcriptionList}
        </Box>
    </>;
}

export default FileDetails;