import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, styled} from "@mui/material";
import Button from "@mui/material/Button";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useApiHook from "../hooks/useApiHook";
import {useEffect} from "react";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUploadDialog = (props) => {
    const {response, makeRequest} = useApiHook();

    const uploadBtnClickHandler = (ev) => {
        makeRequest('file', 'post', {audioFile: ev.target.files[0]} );
    }

    useEffect(() => {
        if (response) {
            if (response.state === 'success') {
                props.onFileUpload();
                props.onClose();
            } else {
                // TODO: gestire errore
            }
        }
    }, [response]);

    return <>
        <Dialog
            open={props.open}
            onClose={props.onClose}
            >
            <DialogTitle>Nuovo file</DialogTitle>
            <DialogContent>
                <DialogContentText>Aggiungi un nuovo file da trascrivere.
                    <br />Il processo di trascrizione inizierà una volta caricato il file
                </DialogContentText>
                <Stack mt={4} alignItems="center" justifyContent="center">
                    <Button
                        size={"large"}
                        component={'label'}
                        tabIndex={-1}
                        variant="contained"
                        color="primary"
                        startIcon={<FileUploadIcon />}
                    >
                        Seleziona file
                        <VisuallyHiddenInput
                            type="file"
                            accept="audio/mp3"
                            onChange={uploadBtnClickHandler}
                        />
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={props.onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default FileUploadDialog;