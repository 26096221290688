import {BrowserRouter, Routes, Route} from "react-router-dom";

import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import DashboardPage from "./pages/Dashboard";
import FilePage from "./pages/File";
import Auth from "./pages/Auth";
import ProtectedRoute from "./routes/ProtectedRoute";
import {AuthProvider} from "./store/auth-context";

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<RootLayout/>}>
                        <Route element={<ProtectedRoute/>}>
                            <Route index element={<HomePage/>}/>
                            <Route path="dashboard" element={<DashboardPage/>}/>
                            <Route path="file/:fileId" element={<FilePage/>}/>
                        </Route>
                        <Route path="*" element={<ErrorPage/>}/>
                    </Route>
                    <Route path="auth" element={<Auth/>}/>
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
