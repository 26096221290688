import {
    Box,
    Button, Card, CardActions, CardContent, CardMedia, CircularProgress,
    Container,
    Paper,
    Stack,
    TextField
} from "@mui/material";
import { useAuth } from "../store/auth-context";
import {useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const AuthPage = () => {
    const [registrationMode, setRegistrationMode] = useState(false);

    const changeModeBtnClickHandler = () => {
        setRegistrationMode(prevState => !prevState);
    }

    return <>
        <Container maxWidth='false' sx={{background: '#21D4FD', backgroundImage: 'linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)', height: '100dvh', width: '100vw', overflow: 'hidden'}}>
            <Paper sx={{ py:5, px: 4, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                {registrationMode && <Register
                    onModeChange={changeModeBtnClickHandler}
                />}
                {!registrationMode && <Login
                    onModeChange={changeModeBtnClickHandler}
                    />}
            </Paper>

        </Container>
    </>
}

const Register = (props) => {
    const authCtx = useAuth();

    const elementRefs = {
        email: useRef(''),
        password: useRef(''),
        confirmPassword: useRef(''),
        name: useRef(''),
        lastname: useRef('')
    };

    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!authCtx.response)
            return;
        if(authCtx.response.state === 'success') {
            setSuccess(true);
            authCtx.login(elementRefs.email.current.value.trim(), elementRefs.password.current.value.trim());
            console.log('Attempting to login');
        } else {
            console.log("Auth error => ", authCtx.response);
            if (authCtx.response.error.code === 409) {
                setErrors([...errors, "emailNotAvailable", "email"]);
                setLoading(false);
            }
        }
    }, [authCtx.response]);

    const checkElementValidity = element => {
        let pattern = null;
        let error = false;
        switch (element) {
            case 'email':
                pattern = /^\S+@\S+\.\S+$/;
                error = !(pattern.test(elementRefs.email.current.value.trim()));
                break;
            case 'password':
                pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
                error = !(pattern.test(elementRefs.password.current.value.trim()));
                break;
            case 'confirmPassword':
                error = !(elementRefs.confirmPassword.current.value.trim() !== "" && elementRefs.password.current.value.trim() === elementRefs.confirmPassword.current.value.trim() && !errors.includes('password'));
                break;
            case 'name':
                error = !(elementRefs.name.current.value.trim() !== "");
                break;
            case 'lastname':
                error = !(elementRefs.lastname.current.value.trim() !== "");
                break;
        }

        if (error) {
            if (errors.includes(element))
                return false;
            setErrors(prevState => [...prevState, element]);
            return false;
        } else {
            if (errors.includes(element))
                setErrors(prevState => prevState.filter(err => err !== element));
            return true;
        }
    };

    const checkFormValidity = () => {
        setErrors([]);
        let isValid = true;
        Object.keys(elementRefs).forEach((key) => {
            if (!checkElementValidity(key))
                isValid = false;
        })
        return isValid;
    }

    const onSubmitHandler = (ev) => {
        ev.preventDefault();
        if (checkFormValidity()) {
            const email = elementRefs.email.current.value.trim();
            const password = elementRefs.password.current.value.trim();
            const name = elementRefs.name.current.value.trim();
            const lastname = elementRefs.lastname.current.value.trim();

            authCtx.register(email, password, name, lastname);
            setLoading(true)
        }
    }

    return <>
        <Stack direction='row'>
            <Box p={5} minWidth={400} sx={{borderRight: '1px solid #00000021'}}>
                <Stack direction='column' spacing={2} alignItems='center' justifyContent='center'>
                    <img width="100" height="100" src="/logo192.png" alt="Logo Sbobbi"/>
                    <Typography variant="h3" mt={-3} mb={5} component="div">Sbobbi</Typography>
                </Stack>
                <Typography variant="h6" mt={5}>Registrati ora per iniziare a trascrivere i tuoi file audio in
                    testo!</Typography>
                <Typography variant='h6' mb={3}> Crea il tuo account in pochi secondi e accedi subito a tutte le
                    funzionalità per semplificare il tuo lavoro.</Typography>
                <Typography variant='body1'>Hai già un account? <a href='#' onClick={props.onModeChange}>Accedi!</a>
                </Typography>
            </Box>
            <Box p={5} minWidth={350}>
                <form action="" onSubmit={onSubmitHandler}>
                    <TextField
                        id="email-input"
                        error={errors.includes('email')}
                        helperText={errors.includes('email') ? "Email non valida!" : ""}
                        label="Email"
                        variant="outlined"
                        autoComplete="email"
                        inputRef={elementRefs.email}
                        onBlur={checkElementValidity.bind(this, "email")}
                        fullWidth={true}
                        sx={{mb: 2}}
                    />
                    <TextField
                        id="password-input"
                        error={errors.includes('password')}
                        helperText={errors.includes('password') ? "La password deve contenere almeno una lettera maiuscola, una minuscola, un numero, un simbolo e deve essere lunga almeno 8 caratteri!" : ""}
                        label="Password"
                        type="password"
                        variant="outlined"
                        autoComplete="current-password"
                        inputRef={elementRefs.password}
                        onBlur={checkElementValidity.bind(this, "password")}
                        fullWidth={true}
                        sx={{mb: 2}}
                    />
                    <TextField
                        id="confirm-password-input"
                        error={errors.includes('confirmPassword')}
                        helperText={errors.includes('confirmPassword') ? "Le password non coincidono!" : ""}
                        label="Conferma password"
                        type="password"
                        variant="outlined"
                        autoComplete="current-password"
                        inputRef={elementRefs.confirmPassword}
                        onBlur={checkElementValidity.bind(this, "confirmPassword")}
                        fullWidth={true}
                        sx={{mb: 2}}
                    />
                    <TextField
                        id="name-input"
                        error={errors.includes('name')}
                        helperText={errors.includes('name') ? "Inserire il nome!" : ""}
                        label="Nome"
                        variant="outlined"
                        autoComplete="name"
                        inputRef={elementRefs.name}
                        onBlur={checkElementValidity.bind(this, "name")}
                        fullWidth={true}
                        sx={{mb: 2}}
                    />
                    <TextField
                        id="lastname-input"
                        error={errors.includes('lastname')}
                        helperText={errors.includes('lastname') ? "Inserire il cognome!" : ""}
                        label="Cognome"
                        variant="outlined"
                        autoComplete="lastname"
                        inputRef={elementRefs.lastname}
                        onBlur={checkElementValidity.bind(this, "lastname")}
                        fullWidth={true}
                        sx={{mb: 2}}
                    />
                    <Stack direction="row" alignItems="center" mb={5} spacing={3}>
                        {errors.includes('emailNotAvailable') && <>
                            <ErrorIcon color='error' fontSize="large"/>
                            <Typography
                                variant="body1"
                                component="div"
                                color="error">
                                Questo indirizzo email è associato ad un account esistente
                            </Typography>
                        </>}
                        {success && <>
                            <ThumbUpIcon color='success' fontSize="large"/>
                            <Typography
                                variant="body1"
                                component="div"
                                color="success">
                                Registrazione avvenuta con successo! Login in corso...
                            </Typography>
                        </>}
                    </Stack>
                    <Box textAlign="center">
                    {!loading && <Button sx={{height: 50, fontSize: 18}} size='large' type='submit' variant='contained'
                            fullWidth={true}>
                        Registrati
                    </Button>}
                    {loading && <CircularProgress />}
                    </Box>
                </form>
            </Box>
        </Stack>
    </>
}

const Login = (props) => {
    const emailElement = useRef('');
    const passwordElement = useRef('');

    const [emailIsValid, setEmailIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkFormValidity = () => {
        return checkElementValidity('email') && checkElementValidity('password');
    }

    const authCtx = useAuth();

    useEffect(() => {
        if (authCtx.response && authCtx.response.request.requestType === 'login') {
            setLoading(false);
            if (authCtx.response.state !== "success"){
                setError(true);
            }
        }
    }, [authCtx.response]);

    const checkElementValidity = element => {
        let pattern = null;
        let isValid = true;
        switch (element) {
            case 'email':
                pattern = /^\S+@\S+\.\S+$/;
                if (pattern.test(emailElement.current.value.trim())) {
                    setEmailIsValid(true);
                } else {
                    isValid = false;
                    setEmailIsValid(false);
                }
                break;
            case 'password':
                if (passwordElement.current.value.trim() !== "") {
                    setPasswordIsValid(true);
                } else {
                    isValid = false;
                    setPasswordIsValid(false);
                }
                break;
        }
        return isValid;
    }

    const onSubmitHandler = (ev) => {
        setError(false);
        ev.preventDefault();
        if (checkFormValidity()) {
            setLoading(true);
            authCtx.login(emailElement.current.value.trim(), passwordElement.current.value.trim());
        }
    }

    return <>
        <form action='' onSubmit={onSubmitHandler}>
            <Card sx={{ py:5, px: 4, minWidth: 400, maxWidth: '', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                <CardMedia
                    sx={{borderRadius: 1, mx: 'auto', p: '25px', width: 'auto'}}
                    component="img"
                    alt="Logo Sbobbi"
                    height="150"
                    image="/logo192.png"
                    />
                <CardContent sx={{ p: 2, textAlign: 'center'}}>
                    <Typography variant="h3" mt={-5} mb={5} component="div">Sbobbi</Typography>
                    <Box sx={{ mb: 2}}>
                        <TextField
                            id="email-input"
                            error={ !emailIsValid }
                            helperText={ emailIsValid ? "" : "Email non valida!" }
                            label="Email"
                            variant="outlined"
                            autoComplete="email"
                            inputRef={emailElement}
                            onBlur={checkElementValidity.bind(this, "email")}
                            fullWidth={true}
                        />
                    </Box>
                    <Box>
                        <TextField
                            id="password-input"
                            error={ !passwordIsValid }
                            helperText={ passwordIsValid ? "" : "Inserire una password!" }
                            label="Password"
                            type="password"
                            variant="outlined"
                            autoComplete="current-password"
                            inputRef={passwordElement}
                            onBlur={checkElementValidity.bind(this, "password")}
                            fullWidth={true}
                        />
                    </Box>
                </CardContent>
                <CardActions sx={{p: 2, justifyContent: 'center', flexDirection: 'column'}}>
                    {error && <Stack direction="row" mb={5} alignItems="center" spacing={3}>
                        <ErrorIcon color='error' fontSize="large"/>
                        <Typography
                            variant="body1"
                            component="div"
                            color="error">
                            Username o Password errata! Riprova...
                        </Typography>
                    </Stack>}
                    <Box textAlign="center">
                        {!loading && <Button sx={{height: 50, fontSize: 18}} type='submit' size='large' variant='contained' fullWidth={true} >Accedi</Button>}
                        {loading && <CircularProgress />}
                    </Box>
                    <Typography mt={3} variant='body1'>
                        Non hai un account? <a href="#" onClick={props.onModeChange}>Registrati adesso!</a>
                    </Typography>
                </CardActions>
            </Card>
        </form>
    </>
}

export default AuthPage;