import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from "@mui/material/Box";
import moment from "moment";
import {useEffect, useState} from "react";
import useApiHook from "../hooks/useApiHook";
import FileUploadDialog from "./FileUploadDialog";
import {useNavigate} from "react-router-dom";

moment.locale('it');

const dummyFiles = [
    {
        id: 1,
        name: "Nome file 1",
        uploadDate: new Date(),
        duration: "03:34",
        state: "waiting"
    },
    {
        id: 2,
        name: "Nome file 2",
        uploadDate: new Date(),
        duration: "00:45",
        state: "done"
    },
    {
        id: 3,
        name: "Nome file 3",
        uploadDate: new Date(),
        duration: "02:21",
        state: "done"
    },
];

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 80
    },
    {
        field: 'name',
        headerName: 'Nome file',
        flex: 1
    },
    {
        field: 'uploadDate',
        headerName: 'Data',
        width: 230,
        valueFormatter: (date) => {
            return moment(date).format('D MMMM YYYY, HH:mm:ss');
        }
    },
    {
        field: 'duration',
        headerName: 'Durata',
        width: 110,
        valueFormatter: (duration) => {
            const splittedD = duration.split(':');
            return `${splittedD[0] != 0 ? +splittedD[0].toString() + "m " : ""}${+splittedD[1].toString()}s`;
        }
    },
    {
        field: 'state',
        headerName: 'Stato',
        width: 100,
    },
];

const FileList = () => {
    const [files, setFiles] = useState([]);
    const {response, makeRequest} = useApiHook();
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            const filesList = [];
            response.forEach((file) => {
                filesList.push({
                    id: file.id,
                    name: file.name,
                    uploadDate: new Date(file.createdAt),
                    state: file.status,
                    duration: "00:00"
                });
                setFiles(filesList);
            });
        }
    }, [response]);

    useEffect(() => {
        makeRequest('file/getAll');
    }, []);

    const onFileUpload = () => {
        makeRequest('file/getAll');
    }

    const uploadFileBtnClickHandler = () => {
        setUploadDialogOpen(true);
    }

    const rowClickHandler = (ev) => {
        navigate(`/file/${ev.id}`);
    }

    return <>
        <FileUploadDialog open={uploadDialogOpen} onFileUpload={onFileUpload} onClose={() => setUploadDialogOpen(false)}></FileUploadDialog>
        <Stack spacing={2} direction="row" alignItems="center" >
            <LibraryBooksIcon fontSize="large" />
            <Typography variant='h4' fontWeight='bold' flexGrow={1}>Lista File</Typography>
            <Button
                size="large"
                variant="contained"
                onClick={uploadFileBtnClickHandler}
                startIcon={<UploadFileIcon />}>
                Nuovo File
            </Button>
        </Stack>
        <Box my={3}>
            <DataGrid
                onRowClick={rowClickHandler}
                rows={files}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10
                        }
                    }
                }}
                pageSizeOptions={[5, 10, 15]}
                checkboxSelection
                disableRowSelectionOnClick
                />
        </Box>
    </>
}

export default FileList;