import Container from "@mui/material/Container";
import {Paper} from "@mui/material";

const Body = props => {
    return <>
        <Container fixed>
            <Paper
                sx={{
                    p: 3,
                    my: 2
                }}
                elevation={3}>
                {props.mainElement}
            </Paper>
        </Container>
    </>
}

export default Body;